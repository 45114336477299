import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"

import {
  Main,
  Container,
  Header,
  Section,
  EpisodeCard,
  Footer,
  ListenToButton,
  Tag,
} from "../components/Lib"
import { transformEpisodesData, toPersianNumber } from "../utils/helpers"

const Episodes = ({ data }) => {
  const episodes = transformEpisodesData(data.episodes.edges)
  const latestEpisode = episodes[0]
  const [isPhone, setIsPhone] = useState(false)
  useEffect(() => {
    setIsPhone(window.innerWidth <= 425)
  }, [])
  return (
    <Container>
      <SEO
        title="اپیزود های پادکست زندگینامه بایوکست"
        description="ما در پادکست فارسی بایوکست، داستان زندگی افراد موفقی رو تعریف می‌کنیم که
            بر موانع و سختی‌های زندگیشون چیره شدن و تاثیر بزرگی از خودشون به جا
            گذاشتند."
        keywords={["بایوکست", "پادکست زندگینامه", "زندگینامه افراد موفق"]}
      />
      <Header />
      <Main>
        <Section className="episodes_page__latestEpisode">
          <div className="inner_wrapper">
            <section className="episodes_page__latestEpisode__info">
              <div className="episodes_page__latestEpisode__number">
                {toPersianNumber(latestEpisode.number)}
              </div>
              <Tag>جدیدترین اپیزود</Tag>
              <h2 className="episodes_page__latestEpisode__title">
                {latestEpisode.title}
              </h2>

              <p className="episodes_page__latestEpisode__description">
                {latestEpisode.description}
              </p>
              <ListenToButton
                Tag="link"
                to={`/episode/${latestEpisode.slug}`}
              />
            </section>
            <EpisodeCard
              tag="Link"
              item={latestEpisode}
              standalone={isPhone}
              overlayColor="--gray"
            />
          </div>
        </Section>
        <Section
          className="episodes_page__episodes"
          backgroundColor="var(--lightgray)"
        >
          <h1 className="episodes_page__episodes__title">اپیزودهای گذشته</h1>
          <section className="grid episodes_page__episodes__grid">
            {episodes.slice(1).map(episode => (
              <EpisodeCard
                standalone
                tag="Link"
                key={episode.title}
                item={episode}
              />
            ))}
          </section>
        </Section>
      </Main>
      <Footer />
    </Container>
  )
}

export const query = graphql`
  query {
    episodes: allStrapiEpisode(sort: { fields: episodeNumber, order: DESC }) {
      edges {
        node {
          title
          description
          text
          episodeNumber
          mainImage {
            childImageSharp {
              fluid(quality: 70) {
                src
              }
            }
          }
          squareImage {
            childImageSharp {
              fluid(quality: 70) {
                src
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Episodes
